<template>

    <app-panel v-on:close="$emit('close')">
            
        <app-panel-title :text="title" />
        <app-panel-text><slot></slot></app-panel-text>
    
        <app-panel-content class="buttons">
    
            <app-button text="Confirm" theme="red" :loading="is.loading" v-on:click="onConfirmClick" />
            <app-button text="Close" theme="grey" v-on:click="$emit('close')" />
    
        </app-panel-content>
    
    </app-panel>
    
</template>
    
<script>
    
    export default {
    
        props: ['title', 'text', 'method', 'api'],
    
        data: function() {
    
            return {
                is: {
                    loading: false
                }
            }
    
        },

        created: function() {

            this.$pubsub.$emit('offset', true)

        },

        beforeDestroy: function() {

            this.$pubsub.$emit('offset', false)

        },
    
        methods: {
    
            onConfirmClick: function() {
    
                this.is.loading = true

                if (this.method === 'delete') 
    
                this.$api[this.method || 'post'](this.api.join('/')).then(function() {
    
                    this.$emit('confirmed')
    
                }.bind(this), function() {
    
                    this.is.loading = false
    
                }.bind(this))
    
            }
    
        }
    
    }
    
</script>

<style scoped>

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}
    
</style>
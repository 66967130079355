<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="title" back="Convention.Badges.Holders">

		<app-content-head-actions-item v-if="!isNew && $permissions.badges.holders.codes.enabled" text="Code" icon="qrcode" v-on:click="onQRClick" />
		<app-content-head-actions-item v-if="!isNew && $permissions.badges.holders.labels.enabled" text="Badge" icon="badge" v-on:click="onBadgeClick" />

		<template v-if="$authorised('con/badges/access', 'write')">

		<app-content-head-actions-item :divide="true" v-if="!isNew && $convention.setup.service.registration" text="Add-on" icon="addon" v-on:click="onAddClick" />
		<app-content-head-actions-item v-if="!isNew && $convention.settings.badges.stacking.enabled" text="Stack" icon="badges.stack" v-on:click="onStackClick" />
		<app-content-head-actions-item v-if="$permissions.badges.holders.edit.enabled" :divide="!isNew" :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item v-if="$permissions.badges.holders.edit.enabled" :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew && $permissions.badges.holders.delete.enabled" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

		</template>

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-summary v-if="!isNew">

			<app-content-summary-item label="No." :value="item.reference" />
			<app-content-summary-item label="Ref." :value="item.referenceRandom" />
			<app-content-summary-item v-if="$convention.setup.service.registration" label="Status" :tag="references.status[item.status]" :status="statusTagType[item.status]" />
			<app-content-summary-item v-if="$convention.setup.service.registration" label="Method" :value="references.methods[item.method]" />
			<app-content-summary-item label="Created" :grow="true" :value="item.date | localDate('MM/DD/YYYY h:mma')" />
			<app-content-summary-item v-if="$convention.setup.service.registration" label="Add-ons" :value="item.count.addons" />
			<app-content-summary-item v-if="item.method === $constants.badge.method.registered && item.sale" label="Sale" :value="item.sale.reference" />
			<app-content-summary-item label="Checked in" :value="item.received | localDate('MM/DD/YYYY h:mma')" v-if="item.received" />
			<app-content-summary-item label="Checked in" value="No" v-if="!item.received" />

		</app-content-summary>

		<app-content-summary title="Account" icon="account" v-if="!isNew && hasAccounts">

			<app-content-summary-item label="Reference" :value="item.user.reference" />
			<app-content-summary-item label="Name" :grow="true" :value="item.user.name" />
			<app-content-summary-item label="E-mail" :grow="true" :value="item.user.email" />
			
		</app-content-summary>

		<app-content-box title="Badge" icon="badge" v-if="!isNew" :readonly="$authorised('con/badges/access', 'read')">

			<app-input-text v-if="!$convention.setup.service.registration" label="Reference" v-model="model.referenceExternal" :validation="$v.model.referenceExternal" placeholder="Enter reference" :maxlength="128" />
			<app-input-text label="First name" v-model="model.firstname" :validation="$v.model.firstname" placeholder="Enter first name" :maxlength="32" />
			<app-input-text label="Last name" v-model="model.lastname" :validation="$v.model.lastname" placeholder="Enter last name" :maxlength="32" />
			<app-input-select v-if="$permissions.badges.types.enabled && references.types.length && !item.stacked" label="Type" v-model="model.type" :validation="$v.model.type" placeholder="Select badge type" :options="typeOptions" />
			<app-input-select v-if="$permissions.badges.groups.enabled && references.groups.length" :allowDeselect="true" label="Group" v-model="model.group" :validation="$v.model.group" placeholder="Select optional group" :options="groupOptions" />

		</app-content-box>

		<app-content-box title="More information" icon="info" v-if="!isNew && $convention.setup.service.registration && $_.keys(item.capture).length" :not-padded="true">

			<app-table :empty="!$_.keys(item.capture).length" emptyMessage="There is no additional information.">

				<app-table-head :layout="infoLayout">

					<app-table-column text="Question" />
					<app-table-column text="Answer" />

				</app-table-head>

				<app-table-body>

					<app-table-row :layout="infoLayout" v-for="(item, id) in item.capture" :key="id">

						<app-table-cell-text :text="item.label" />
						<app-table-cell-text :text="item.value" />

					</app-table-row>

				</app-table-body>

			</app-table>

		</app-content-box>

		<app-content-box v-if="isNew" :readonly="$authorised('con/badges/access', 'read')">

			<app-input-text v-if="!$convention.setup.service.registration" label="Reference" v-model="model.referenceExternal" :validation="$v.model.referenceExternal" placeholder="Enter reference" :maxlength="128" />
			<app-input-account v-if="hasAccounts" label="E-mail" type="badge" :text="emailMessages" v-model="model.email" v-on:exists="onExistsChange" :validation="$v.model.email" />
			<app-input-text label="First name" v-model="model.firstname" :validation="$v.model.firstname" placeholder="Enter first name" :maxlength="32" />
			<app-input-text label="Last name" v-model="model.lastname" :validation="$v.model.lastname" placeholder="Enter last name" :maxlength="32" />
			<app-input-select v-if="$permissions.badges.types.enabled && references.types.length" label="Type" v-model="model.type" :validation="$v.model.type" placeholder="Select badge type" :options="typeOptions" />
			<app-input-select v-if="$permissions.badges.groups.enabled && references.groups.length" label="Group" v-model="model.group" :validation="$v.model.group" placeholder="Select optional group" :options="groupOptions" />
			<app-input-toggle v-if="$convention.setup.service.registration" label="Requires Purchase" v-model="model.status" :validation="$v.model.status" :options="statusOptions" />

		</app-content-box>

		<app-content-box title="Stacked Types" icon="badges.stacked" v-if="!isNew && $convention.setup.service.registration && item.stacked" :not-padded="true">

			<app-table :empty="!item.stacked.length" emptyMessage="There are no stacked types.">

				<app-table-head :layout="stackedLayout">

					<app-table-column text="Type" />
					<app-table-column text="Sale No." />
					<app-table-column text="Sale Date" />
					<app-table-column />

				</app-table-head>

				<app-table-body>

					<app-table-row :layout="stackedLayout" v-for="item in item.stacked" :key="item.id">

						<app-table-cell-text :text="item.name" />
						<app-table-cell-text :text="(item.sale) ? item.sale.reference : '-'" />
						<app-table-cell-text v-if="item.sale" :text="item.sale.date.made | localDate('MM/DD/YYYY h:mma')" />
						<app-table-cell-text v-if="!item.sale" text="-" />
						<app-table-cell-action v-if="!item.sale" icon="remove" v-tooltip="'Remove badge'" :loading="is.removingStack" v-on:click="onRemoveStack(item)" />
						<app-table-cell-action v-if="item.sale" icon="refund" v-tooltip="'Refund badge'" :loading="is.removingStack" v-on:click="onRemoveStack(item)" />

					</app-table-row>

				</app-table-body>

			</app-table>

		</app-content-box>

		<app-content-box title="Add-ons" icon="addons" v-if="!isNew && $convention.setup.service.registration" :not-padded="true">

			<app-table :empty="!item.addons.length" emptyMessage="There are no add-ons.">

				<app-table-head :layout="addonLayout">

					<app-table-column text="Name" />
					<app-table-column align="center" text="Checkin Status" />
					<app-table-column text="Checkin Date" />
					<app-table-column text="Sale No." />
					<app-table-column text="Sale Date" />

				</app-table-head>

				<app-table-body>

					<app-table-row :layout="addonLayout" v-for="item in item.addons" :key="item.id">

						<app-table-cell-text :text="item.name" />
						<app-table-cell-text v-if="!item.sale" text="-" />
						<app-table-cell-icon align="center" :theme="(item.received) ? 'green' : 'grey'" :icon="(item.received) ? 'received' : 'notreceived'" />
						<app-table-cell-text v-if="item.received" :text="item.received  | localDate('MM/DD/YYYY h:mma')" />
						<app-table-cell-text v-if="!item.received" text="-" />
						<app-table-cell-text :text="(item.sale) ? item.sale.reference : '-'" />
						<app-table-cell-text v-if="item.sale" :text="item.sale.date.made | localDate('MM/DD/YYYY h:mma')" />

					</app-table-row>

				</app-table-body>

			</app-table>

		</app-content-box>

		<app-content-box title="Managed Badges" icon="badges.managed" v-if="!isNew && $convention.setup.service.registration && item.managed.length" :not-padded="true">

			<app-table :empty="!item.managed.length" emptyMessage="There are no managed badges.">

				<app-table-head :layout="managedLayout">

					<app-table-column text="Name" />
					<app-table-column text="Sale No." />
					<app-table-column text="Sale Date" />

				</app-table-head>

				<app-table-body>

					<app-table-row :layout="managedLayout" v-for="item in item.managed" :key="item.id">

						<app-table-cell-text :text="item.name" />
						<app-table-cell-text :text="(item.sale) ? item.sale.reference : '-'" />
						<app-table-cell-text v-if="item.sale" :text="item.sale.date.made | localDate('MM/DD/YYYY h:mma')" />
						<app-table-cell-text v-if="!item.sale" text="-" />

					</app-table-row>

				</app-table-body>

			</app-table>

		</app-content-box>

		<app-input-items-find title="Find add-on to comp" v-if="is.finding && $authorised('con/badges/access', 'write')" api="convention/registration/item/variant" v-on:save="onAddSaveClick" v-on:close="is.finding = false" />
		<app-input-items-find title="Find badge to stack" placeholder="Search by name" v-if="is.stacking && $authorised('con/badges/access', 'write')" api="convention/badge/type" :exclude="excludeStacked" v-on:save="onStackSaveClick" v-on:close="is.stacking = false" />
		
		<com-confirm title="Remove stacked badge" method="delete" v-if="is.removingStack && $authorised('con/badges/access', 'write')" :api="['convention/badges/holder', item.id, 'types', is.removingStack]" v-on:confirmed="onRemoveStackComplete" v-on:close="is.removingStack = false">

			Are you sure you wish to remove the stacked <b>{{ confirmStackName }}</b> badge?

		</com-confirm>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, requiredIf, maxLength, email } from 'vuelidate/lib/validators'
import comConfirm from './common/panel/Confirm'

export default {

	mixins: [mixForm],

	components: {
		'com-confirm': comConfirm
	},

	data: function() {

		return {
			readonly: this.$authorised('con/badges/access', 'read'),
			is: {
				finding: false,
				stacking: false,
				removingStack: false
			},
			addonLayout: 'auto 120px 160px 100px 160px',
			managedLayout: 'auto 100px 160px',
			stackedLayout: 'auto 100px 160px 24px',
			infoLayout: '200px auto',
			statusTagType: {
				0: 3,
				1: 1
			},
			statusOptions: {
				0: 'Yes',
				1: 'No'
			},
			emailMessages: {
				info: 'The e-mail address used must not already be associated with an existing badge for this convention.',
				added: 'This e-mail address is already associated with a badge.',
				exists: 'This e-mail address is already associated with an existing Cardboard Events account but they do not have a badge.',
				register: 'A Cardboard Events login will be generated for this e-mail address and will be associated with their badge. The login credentials will automatically be e-mailed to the user.'
			},
			references: {
				types: [],
				groups: [],
				methods: [],
				status: []
			},
			model: {
				id: false,
				referenceExternal: '',
				reference: '',
				firstname: '',
				lastname: '',
				email: '',
				group: 0,
				method: 2,
				status: 1,
				type: 0
			},
			added: false
		}

	},

	validations: {
		model: {
			firstname: {
				required,
				maxLength: maxLength(32)
			},
			lastname: {
				required,
				maxLength: maxLength(32)
			},
			referenceExternal: {
				requiredIf: requiredIf(function() {

					return !this.$convention.setup.service.registration

				})
			},
			type: {
				requiredIf: requiredIf(function() {

					return this.$permissions.badges.types.enabled && this.references.types.length

				})
			},
			method: {
				requiredIf: requiredIf(function() {

					return this.$convention.setup.service.registration

				})
			},
			status: {
				requiredIf: requiredIf(function() {

					return this.$convention.setup.service.registration

				})
			},
			email: {
				requiredIf: requiredIf(function() {

					return this.hasAccounts

				}),
				email
			}
		}
	},

	computed: {

		confirmStackName: function() {

			if (this.is.removingStack) {

				return this.$_.findWhere(this.item.stacked, {
					id: this.is.removingStack
				}).name

			} else {

				return ''

			}

		},

		excludeStacked: function() {

			return this.$_.pluck(this.item.stacked, 'type')

		},

		hasAccounts: function() {

			return this.$convention.setup.service.registration || this.$convention.setup.service.schedule

		},

		typeOptions: function() {

			var options = {}

			this.$_.each(this.references.types, function(type) {

				options[type.id] = type.name

			})

			return options

		},

		groupOptions: function() {

			var options = {}

			this.$_.each(this.references.groups, function(type) {

				options[type.id] = type.name

			})

			return options

		},

		methodOptions: function() {

			return this.references.methods

		}

	},

	methods: {

		onRemoveStackComplete: async function() {

			await this.loadItem()

			this.is.removingStack = false

		},

		onRemoveStack: function(stack) {

			if (stack.sale) {

				this.$router.push({
					name: 'Convention.Registration.Sales.Edit',
					params: {
						id: stack.sale.id
					}
				})

			} else {

				this.is.removingStack = stack.id

			}

		},

		onExistsChange: function(e) {

			this.added = e.added

		},

		onAddSaveClick: function(addons) {

			this.is.loading = true

			this.$api.post(['convention/badges/holder', this.model.id, 'variants'], {
				keys: addons
			}).then(function() {

				this.load()

			}.bind(this))

			this.is.finding = false

		},

		onStackSaveClick: function(types) {

			this.is.loading = true

			this.$api.post(['convention/badges/holder', this.model.id, 'types'], {
				keys: types
			}).then(function() {

				this.load()

			}.bind(this))

			this.is.stacking = false

		},

		onAddClick: function() {

			this.is.finding = true

		},

		onStackClick: function() {

			this.is.stacking = true

		},

		onQRClick: function() {

			this.is.loading = true

			this.$api.get(['qr', this.model.id]).then(function(json) {

				this.$present(json.qr)

				this.is.loading = false

			}.bind(this))

		},

		onBadgeClick: function() {

			this.is.loading = true

			this.$api.get(['badge', this.model.id]).then(function(json) {

				this.$present(json.badge)

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>